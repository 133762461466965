import { Subject } from 'rxjs'
import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Member } from 'app/models'

@Injectable({providedIn:'root'})
export class AttendeesService {
  private _subjects: Record<number, Subject<Member[]>> = {}

  constructor (private $http: HttpClient) { }

  listForClass (classId: number) {
    const subject = this.getSubject(classId)
    this.$http.get<Member[]>(`/api/attendees/${classId}`).toPromise().then(r => subject.next(r), r => { throw r.error })
    return subject.asObservable()
  }

  notifyUpdate = (data: {session_id: number, attendance: any[]}) => {
    const subject = this.getSubject(data.session_id)
    subject.next(data.attendance)
  }

  private getSubject = (classId: number) => {
    // classId = parseInt(classId, 10)
    let subject = this._subjects[classId]
    if (!subject) { this._subjects[classId] = subject = new Subject() }
    return subject
  }
}
