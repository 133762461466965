import { $faye as xFaye } from 'app/factories/index'
import { AttendeesService } from 'app/services'
import { MessageService } from './message_service'
import { Injectable } from '@angular/core'
import { Display } from 'app/models'
import { ChannelService } from './channel_service'
import { ChannelData } from 'app/models/display'

@Injectable({providedIn:'root'})
export class BroadcastService {
  private _messageSubscription: {cancel:()=>void}
  private _channelSubscription: {cancel:()=>void}
  private _workoutSubscription: {cancel:()=>void}
  private _attendanceSubscription: {cancel:()=>void}

  constructor (private $faye: xFaye,
    private messageService: MessageService,
    private channelService: ChannelService,
    private attendeesService: AttendeesService) { }

  subscribe (tv: Display)  {
    this.cancelAll()
    if(!tv){ return }
    this._messageSubscription = this.$faye.subscribe(`/tv/${tv.short_id}/message`, this.processMessage.bind(this))
    this._channelSubscription = this.$faye.subscribe(`/tv/${tv.short_id}/channel`, this.processChannel.bind(this))
    this._workoutSubscription = this.$faye.subscribe(`/tv/${tv.short_id}/workoutresult`, this.processWorkoutResult.bind(this))
    this._attendanceSubscription = this.$faye.subscribe(`/tv/${tv.short_id}/attendance`, (d) => this.attendeesService.notifyUpdate(d))
  }

  cancelAll () {
    this._messageSubscription?.cancel()
    this._channelSubscription?.cancel()
    this._workoutSubscription?.cancel()
    this._attendanceSubscription?.cancel()
  }

  private processMessage = (data: {message?: any}) => {
    if (data.message) { this.messageService.show(data.message) }
  }

  private processChannel = (data: {channel?: ChannelData}) => {
    if (data.channel) { this.channelService.process(data.channel) }
  }

  private processWorkoutResult = (_data: unknown) => {
    //TODO: this.$rootScope.$broadcast('workoutUpdated', data)
  }
}
