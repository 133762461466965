import { Component } from '@angular/core'
import { TvService } from 'app/services'
import { ActivatedRoute, RouterOutlet } from '@angular/router'
import { Display } from 'app/models'

import { distinctUntilChanged, map } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

@Component({
  selector: 'displayBase',
  template: '<router-outlet></router-outlet>',
  standalone: true,
  imports:[RouterOutlet]
})
export default class DisplayBasePage {
  currentTv: Display

  constructor (private tvService: TvService,
    private route: ActivatedRoute) {
    this.route.params.pipe(map(p =>p['tv_id']), distinctUntilChanged(), takeUntilDestroyed()).subscribe((tvId: string) => {
      this.currentTv = this.tvService.switch(tvId)
    })
  }
}
