import { Injectable } from '@angular/core';
import { AuthHelper } from "app/services/auth_helper"

@Injectable({
  providedIn: 'root'
})
export class CastReceiverService {
  private castReceiverManager: cast.receiver.CastReceiverManager;
  private messageBus: cast.receiver.CastMessageBus;
  private readonly CUSTOM_NAMESPACE = 'urn:x-cast:tv.influx.cast';

  constructor (private authHelper: AuthHelper,) {
    // Check if we're running on Chromecast
    if (!this.isChromecast()) {
      return;
    }

    // Initialize receiver manager
    this.castReceiverManager = cast.receiver.CastReceiverManager.getInstance();
    this.setupReceiverListeners();
    this.setupMessageBus();
  }

  private isChromecast (): boolean {
    // https://code.google.com/p/google-cast-sdk/issues/detail?id=157
    return window.navigator.userAgent.indexOf('CrKey') >= 0;
  }

  private setupReceiverListeners (): void {
    // Ready event
    this.castReceiverManager.onReady = (event) => {
      console.log('Received Ready event:', event.data);
      this.castReceiverManager.setApplicationState('Application status is ready...');
    };

    // Sender connected
    this.castReceiverManager.onSenderConnected = (event) => {
      console.log('Sender Connected:', event.data);
      const sender = this.castReceiverManager.getSender(event.data);
      console.log('Sender UserAgent:', sender.userAgent);
    };

    // Sender disconnected
    this.castReceiverManager.onSenderDisconnected = (event) => {
      console.log('Sender Disconnected:', event.data);
      // Optional: Close window if no senders
      // if (this.castReceiverManager.getSenders().length === 0) {
      //   window.close();
      // }
    };
  }

  private setupMessageBus (): void {
    this.messageBus = this.castReceiverManager.getCastMessageBus(this.CUSTOM_NAMESPACE);

    this.messageBus.onMessage = (event) => {
      console.log(`Message [${event.senderId}]:`, event.data);

      try {
        const data = JSON.parse(event.data);
        // Echo message back to sender
        this.messageBus.send(event.senderId, event.data);

        // Handle authentication
        // Note: You'll want to inject your auth service properly instead
        // of using the old angular.element approach
        this.handleAuthentication(data);
      } catch (error) {
        console.error('Error processing message:', error);
      }
    };
  }

  private handleAuthentication (data: any): void {
    this.authHelper.initialise(data)
  }

  initializeReceiver (): void {
    if (!this.isChromecast()) {
      return;
    }

    cast.receiver.logger.setLevelValue(0);

    this.castReceiverManager.start({
      statusText: 'Application is starting'
    });

    console.log('Receiver Manager started');
  }
}
