import { Component } from '@angular/core'
import { ConfigureBoxComponent } from 'app/components/configure'

@Component({
  templateUrl: './index.html',
  standalone:true,
  imports: [ConfigureBoxComponent]
})
export class HomePage {
}

