@if(viewstate == 'login'){
  <div class="card-header"><h4 class="my-0">Login to configure your InfluxTv</h4></div>
  <div class="card-body">
    <p class="alert alert-danger" *ngIf="!!message" [innerText]="message"></p>
    <form name="loginForm"  #loginForm="ngForm" >
    <div class="form-group">
      <label for="email" class="right inline">Email</label>
      <input type="email" class="form-control" [(ngModel)]="auth.email" id="email" name="loginEmail" placeholder="Your Admin login email" required>
    </div>
    <div class="form-group">
      <label for="password" class="right inline">Password</label>
      <input type="password" class="form-control" [(ngModel)]="auth.password" id="password" name="loginPassword" placeholder="Your Admin password" autocomplete="current-password" required>
    </div>
    </form>
  </div>
  <div class="card-footer d-flex">
    <button type="button" class="btn btn-primary ms-auto" (click)="login(auth.email, auth.password)" [disabled]="loginForm.invalid">Login</button>
  </div>
}
@else if(viewstate == 'tvs'){
  <div class="card-header"><h4 class="my-0">Select facility</h4></div>
  <div class="card-body">
    @if(!selected){
    <div class="facility-list">
      @for(facility of facilities; track facility.id){
        <div class="facility-item" (click)="selected = facility">
          <img [src]="facility.logo_url" alt="">{{facility.name}}</div>
      }
    </div>
    } @else {
      <div class="facility-list">
        <div class="facility-item">
          <img [src]="selected.logo_url" alt="">{{selected.name}}</div>
      </div>
    <div *ngIf="selected.displays.length == 0">
      <p>No displays registered. Please register a new one.</p>
    </div>
    <div *ngIf="selected.displays.length > 0">
      <p>There are {{selected.displays.length}} tvs registered at this facility</p>
      <table class="table" style="width:100%;margin-bottom:0;">
        <tr><th>Location</th><th>Action</th></tr>
        <tr *ngFor="let display of selected.displays">
          <td [innerText]="display.name"></td>
          <td><button class="btn btn-sm btn-outline-dark" (click)="selectDisplay(selected, display)">Select</button></td>
        </tr>
      </table>
    </div>

      }
  </div>
  <div class="card-footer d-flex" *ngIf="selected">
    <button class="btn" (click)="selected=undefined">Back</button>
    <button class="btn btn-primary ms-auto" (click)="showNew(selected)">Register new tv</button>
  </div>
}

@else if (viewstate == 'new_tv') {

  <div class="card-header"><h4 class="my-0">Register tv</h4></div>
  <div class="card-body">
    <form name="newTvForm"  #newTvForm="ngForm">
    <div class="form-group">
      <label for="tv_name" class="right inline">Name</label>
      <input type="text" class="form-control" [(ngModel)]="tv.name" id="tv_name" name="tv_name" placeholder="eg studio wall, reception" required>
    </div>
    </form>
  </div>
  <div class="card-footer">
    <button class="btn" (click)="cancelNew()">Back</button>
    <button class="btn btn-primary ms-auto" (click)="create(tv)" [disabled]="newTvForm.invalid">Register new tv</button>
  </div>

}
