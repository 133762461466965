import { Injectable, inject } from '@angular/core'
import { Router } from '@angular/router'
import { TvService } from './tv_service'
import { ChannelData } from 'app/models/display'

@Injectable({providedIn:'root'})
export class ChannelService {
  tvService = inject(TvService)

  constructor (private router: Router) { }

  process (data: ChannelData) {
    const tvId = this.tvService.currentTv?.short_id
    let url = ''
    switch (data.type) {
      case 'blank': url = `/${tvId}`; break;
      case 'attendees': url = `/${tvId}/class/${data.session_id}`; break;
      case 'workout':
      case 'warmup':
      case 'warmdown':
        url = `/${tvId}/class/${data.session_id}/entry/${data.workout_id}`;
        break;
      case 'classResults': url = `/${tvId}/class/${data.session_id}/results/${data.workout_id}`; break;
      case 'whiteboard': url = `/${tvId}/whiteboard/${data.id}/${data.date}`; break;
      case 'leaderboard': url = `/${tvId}/leaderboard/${data.whiteboard_id}/${data.date}`; break;
      default: url = `/${tvId}/debug`;
    }
    return this.router.navigateByUrl(url)
  }
}
