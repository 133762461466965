import { CommonModule } from '@angular/common'
import { Component, EventEmitter, Input, Output } from '@angular/core'
import { Router } from '@angular/router'
import { Display } from 'app/models'
import { AuthService, ChannelService, TvService } from 'app/services'

@Component({
  selector: 'off-canvas-menu',
  template: `<aside class="left-off-canvas-menu" [ngClass]="{'is-opened':isOpened}" *ngIf="!!facility && !!current">
  <div class="facility"><span [innerText]="facility?.name"></span><button class="btn btn-dark float-right" type="button" (click)="toggleMenu.emit()"><i class="fas fa-bars"></i></button></div>
  <ul class="list-group">
  <li class="list-group-item list-group-title"><span>Display</span></li>
  <li class="list-group-item" *ngFor="let display of displays" [ngClass]="{active:current.short_id == display.short_id}"><a (click)="changeDisplay(display)" [innerText]="display.name"></a></li>
  <li class="list-group-item list-group-title"><span>Options</span></li>
  <li class="list-group-item d-flex justify-content-between"><a href="https://docs.influxhq.com/tv" target="_blank">Help </a> <i class="fa fa-question-circle"></i></li>
  <li class="list-group-item"><a href="/" (click)="logout($event)">Logout</a></li>
  </ul>
  </aside>`,
  standalone: true,
  imports: [CommonModule]
})
export class OffCanvasMenuComponent {
  @Input()
  current: any
  @Input()
  facility: { name: string }
  @Input()
  isOpened: boolean
  @Output()
  toggleMenu = new EventEmitter()
  displays: Display[]

  constructor (private tvService: TvService,
    private router: Router,
    private authService: AuthService,
    private channelService: ChannelService) {
    this.tvService.currentDisplays$.subscribe(displays => this.displays = displays)
  }

  changeDisplay (display: Display) {
    this.tvService.set(display)
    this.channelService.process(display.channel)
    this.toggleMenu.emit()
  }

  logout ($event: UIEvent) {
    $event.preventDefault()
    this.authService.deauthorize()
    this.tvService.signOut()
    this.router.navigateByUrl('/')
  }
}
