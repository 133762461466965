import { inject } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivateFn, Router, RouterStateSnapshot } from "@angular/router";
import { TvService } from "app/services";
// import { filter, map, take } from "rxjs";

// export function canActivate () {
//   const tvService = inject(TvService)
//   const router = inject(Router)
//   return tvService.currentTv$.pipe(
//     filter((tv) => tv !== undefined), // Filter out initial Behaviour subject value
//     take(1), // Otherwise the Observable doesn't complete!
//     map((tv) => {
//       if (!tv) {
//         router.navigateByUrl('/tv')
//         return false;
//       }
//       return true;
//     })
//   );
// }

export function tvActivate (): CanActivateFn {

  return (route: ActivatedRouteSnapshot, _state: RouterStateSnapshot) => {
    const tvService = inject(TvService)
    const router = inject(Router)
    const routeName = route.data.name;
    // Are we on the tvRoute
    if (routeName === 'tvRoute') {
      // Access and monitor the route parameter
      const tvId = route.paramMap.get('tv_id');
      if (tvService.switch(tvId)) {
        return true
      }
      console.error(`No TV found for id: `, tvId)
      router.navigateByUrl('/')
      return false
    }
    return true
  }
}
