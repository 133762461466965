
import { TvService } from './tv_service'
import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { Display } from 'app/models'
import { MessageService } from './message_service'

@Injectable({providedIn:'root'})
export class AuthHelper {
  private _parent: any

  constructor (private tvService: TvService,
    private router: Router,
    private Message: MessageService) { }

  initialise = (data: {display: Display}) => {
    const display = new Display(data.display)
    // This code is a bit ugly. We're trying to reuse the set code but it gets caught out with the defaultPage logic needing to look up a list
    // So we manually set the list with this display to get it to work... Should probably be re-written all round
    this.tvService.initialiseTv(display)
    this.tvService.set(display)
    this.tvService.list()
    // The message below doesn't surive the redirect
    this.Message.show({type: 'positive', text: `Connected: ${display.name} ='${display.short_id}'=`})
    this.router.navigateByUrl(`/${display.short_id}/welcome`)
  }

  receiveMessage = (event: MessageEvent) => {
    this._parent = event.source
    if (!event.data || !event.data.display) { return }
    const data = JSON.parse(event.data)
    if (data.display) {
      this.initialise(data)
      this._parent.postMessage(`Received: ${data.display.id}`, '*')
    }
  }
}
