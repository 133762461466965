import { Component } from "@angular/core";
import { RouterOutlet } from "@angular/router";
import { DisplayMessageComponent } from "./components/display_message";
import { OffCanvasMenuComponent } from "./components/off_canvas_menu";
import { NgIf } from "@angular/common";
import { BroadcastService, TvService } from "./services";
import { takeUntilDestroyed } from "@angular/core/rxjs-interop";
import { Display } from "./models";
import { debounceTime, distinctUntilChanged, fromEvent, map } from "rxjs";
import { Theme } from 'app/models/display'
import { CastReceiverService } from "app/services/cast_receiver_service"

@Component({
  selector: 'app',
  templateUrl: './app.html',
  standalone: true,
  imports: [RouterOutlet, DisplayMessageComponent, OffCanvasMenuComponent, NgIf]
})
export class AppComponent {
  pageTitle = 'InfluxTv'
  topBarVisible = false
  isOpened = false
  tv?: Display
  private _originalBackground: string
  sheet: CSSStyleSheet

  constructor ( tvService: TvService, broadcastService: BroadcastService, castReceiverService: CastReceiverService){//}, authHelper: AuthHelper) {
    const tv$ = tvService.currentTv$.pipe(takeUntilDestroyed())
    tv$.subscribe((tv) => { this.tv = tv })
    tv$.pipe(distinctUntilChanged((prev, curr) => prev?.short_id == curr?.short_id)).subscribe((tv) => broadcastService.subscribe(tv))
    tv$.pipe(map((tv)=> tv?.theme), distinctUntilChanged((prev, curr) => prev?.updated == curr?.updated)).subscribe((theme) => this.changeTheme(theme))

    this.initMouseTracking()
    castReceiverService.initializeReceiver()
  }

  toggleMenu () {
    this.isOpened = !this.isOpened
  }

  private initMouseTracking () {
    const mouseMove$ = fromEvent(document, 'mousemove');
    // If mouse moves, then the menu should show if we have a TV
    mouseMove$.subscribe(() => { this.topBarVisible = !!this.tv })
    // If the mouse stops moving, hide the top menu
    mouseMove$
      .pipe(
        debounceTime(5000),
        takeUntilDestroyed()
      )
      .subscribe(() => {
        this.topBarVisible = false
        this.isOpened = false
      })
  }

  private changeTheme (theme: Theme) {
    // Grab the default, then we go back to that if the TV is de-authd
    if(!this._originalBackground) {
      this._originalBackground = getComputedStyle(document.body).backgroundImage;
    }
    document.body.style.backgroundImage = (theme?.background_url) ? `url(${theme.background_url})` : this._originalBackground
    if(theme) {
      this.loadTheme(theme)
    } else {
      this.clearStyleSheet()
    }
  }

  private loadTheme (theme: Theme) {
    if (!theme?.header) { return }
    // https://davidwalsh.name/add-rules-stylesheets
    // Works but need to change the font when it the attribute changes
    //    WebFont.load(window.WebFontConfig)
    window.WebFont.load({ google: { families: [`${ theme.title.font_type }::latin`, `${ theme.header.font_type }::latin`, `${ theme.text.font_type }::latin`, `${ theme.notes.font_type }::latin`] } })

    if (this.sheet) {this.clearStyleSheet()} else {this.prepareStyleSheet()}

    this.sheet.insertRule(`.display-wrapper {background-color:${ theme.background_color };}`, 0)
    this.sheet.insertRule(`.display-wrapper h1 {font-family:'${ theme.title.font_type.replace(/\+/g, ' ') }' !important; color: ${ theme.title.font_color };}`, 1)
    this.sheet.insertRule(`.display-wrapper h2 {font-family:'${ theme.header.font_type.replace(/\+/g, ' ') }' !important; color: ${ theme.header.font_color };}`, 1)
    this.sheet.insertRule(`.display-wrapper {font-family:'${ theme.text.font_type.replace(/\+/g, ' ') }' !important; color: ${ theme.text.font_color };`, 2)
    this.sheet.insertRule(`.display-wrapper .notes {font-family:'${ theme.notes.font_type.replace(/\+/g, ' ') }' !important; color: ${ theme.notes.font_color };`, 3)
  }

  private prepareStyleSheet (){
    const style = document.createElement('style')
    // WebKit hack :(
    style.appendChild(document.createTextNode(''))
    // Add the <style> element to the page
    document.head.appendChild(style)
    this.sheet = style.sheet as CSSStyleSheet
    // sheet.insertRule(".tv-view, .tv-view h1 {font-family:'#{this.currentTv.font_type}' !important;color:lime !important;}",0)
  }

  private clearStyleSheet () {
    for (let i = this.sheet?.cssRules.length - 1; i >= 0; i--) {
      this.sheet.deleteRule(i);
    }
  }
}
