<div class="container d-flex h-100 align-items-center min-vh-100">
    <div class="row w-100">
        <div class="col-6 mx-auto">
          <configure-box class="card h-100 border-dark justify-content-center bg-white"></configure-box>
        </div>
        <div class="col-6 mx-auto">
            <div class="card box-shadow h-100 justify-content-center">
              <div class="card-header">
                <h4 class="my-0 font-weight-normal">Get your gym on Influx.TV</h4>
              </div>
              <div class="card-body">
                <ul class="list-unstyled mb-4">
                  <li>Workouts on your big screen</li>
                  <li>Live workout results</li>
                  <li>Live class attendees and checkins</li>
                  <li>Class and daily leaderboard</li>
                  <li>Multiple displays</li>
                  <li>Themeable &amp; brandable</li>
                </ul>
                <a href="https://docs.influxhq.com/tv" class="btn btn-lg btn-block btn-secondary" rel="noopener" target="_blank"><i class="fa fa-book"></i> Read more</a>
              </div>
            </div>
        </div>
    </div>
</div>
