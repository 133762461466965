import { Injectable } from '@angular/core'

@Injectable({ providedIn: 'root' })
export class LocalStorageService {
  getItem (key: string) {
    return JSON.parse(localStorage.getItem(`influxTv.${key}`))
  }

  setItem (key: string, value: any) {
    return localStorage.setItem(`influxTv.${key}`, JSON.stringify(value))
  }

  removeItem (key: string) {
    return localStorage.removeItem(`influxTv.${key}`)
  }
}
