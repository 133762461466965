import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { WhiteboardItem } from 'app/models'

@Injectable({providedIn:'root'})
export class WhiteboardItemsService {
  constructor (private $http: HttpClient) { }

  get (itemId: number) {
    return this.$http.get(`/api/whiteboard_items/${itemId}`).toPromise().then(r => new WhiteboardItem(r), r => { throw r.error })
  }
}
