import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Auth } from 'app/models'
import { BehaviorSubject, tap } from 'rxjs'

@Injectable({providedIn:'root'})
export class AuthService {
  private _currentAuth = new BehaviorSubject<Auth>(undefined)

  //get currenthAuth$ () { return this._currentAuth.asObservable() }
  get currenthAuth () { return this._currentAuth.value }

  constructor (private $http: HttpClient) { }

  login (email: string, password: string) {
    return this.$http.post<Auth>('/api/authentication', { password, email }).pipe(
      tap((a) => this._currentAuth.next(a))
    )
  }

  deauthorize () {
    this._currentAuth.next(undefined)
  }
}
