import { HttpClient } from '@angular/common/http'
import { BehaviorSubject, firstValueFrom, map, tap } from 'rxjs'
import { Display, Facility } from 'app/models'
import { Injectable } from '@angular/core'
import { LocalStorageService } from './local_storage_service'
import { MessageService } from './message_service'

@Injectable({providedIn: 'root'})
export class TvService {
  private _interval: any
  private _displays$ = new BehaviorSubject<Display[]>([])
  private _currentTv$ = new BehaviorSubject<Display>(undefined) // Undefined important for auth

  get currentTv$ () { return this._currentTv$.asObservable() }
  get currentTv () { return this._currentTv$.value }
  get currentDisplays$ () { return this._displays$.asObservable() }

  constructor (private $http: HttpClient,
               private localStorageService: LocalStorageService,
               private Message: MessageService) {
    const saved = this.localStorageService.getItem('displays') as Display[]
    if (saved) {
      this._displays$.next(saved)
    }
  }


  switch (shortId: string) {
    const found = this._displays$.value.find((d) => d.short_id === shortId)
    this.set(found)
    return this.currentTv
  }

  list () {
    firstValueFrom(this.$http.get<Display[]>('/api/tv')).then(liveDisplays => {
      // Make sure we update the cached example here
      const curKeys = liveDisplays.map(d => d.short_id)
      // Remove existing displays not in our list
      const temp = this._displays$.value.filter(d => !curKeys.includes(d.short_id));
      for (const existing of temp) {
        const update = liveDisplays.find(ld => ld.short_id === existing.short_id)
        Object.assign(existing, update)
      }
      const tmpKeys = temp.map(d => d.short_id)
      // All items not updated above and add them
      const newTvs = liveDisplays.filter(d => !tmpKeys.includes(d.short_id))
      for (const live of newTvs) {
        temp.push(live)
      }
      this.setList(temp)
    })
  }

  setFacility (facility: Facility) {
    this.setList(facility.displays)
  }

  private setList (displays: Display[]) {
    this._displays$.next(displays)
    this.localStorageService.setItem('displays', displays)
  }

  get () {
    return firstValueFrom(this.$http.get<Display>('/api/tv/current').pipe(map((display) => {
      const xd = this._displays$.value.find((d) => d.short_id === display.short_id)
      return xd ? Object.assign(xd, display) : new Display(display)
    }), tap((d) => {
      const _displays = this._displays$.value
      for (let i = 0; i < _displays.length; i++) {
        if (_displays[i].short_id == d.short_id) {
          _displays[i] = d
          this.setList(_displays)
          break
        }
      }
      // TODO add the display if not in this list?
    }))).then(r => new Display(r), r => { throw r.error })
  }

  create (tv: Partial<Display>) {
    return firstValueFrom(this.$http.post('/api/tv', {tv})).then(r => {
      const tv = new Display(r)
      this.initialiseTv(tv)
      return tv
    }, r => { throw r.error })
  }

  initialiseTv (tv:Display) {
    if(this._displays$.value.length == 0) {
      this._displays$.next([tv])
    } else {
      this._displays$.next([...this._displays$.value, tv])
    }
  }

  set (tv: Display) {
    if (tv) {
      this._currentTv$.next(tv)
      if (tv.message) {
        this.Message.show(tv.message)
      }
      this.ensureMonitoring()
    } else {
      this.localStorageService.removeItem('tv')
      this._currentTv$.next(null)
      clearInterval(this._interval)
      this._interval = undefined
    }
  }

  signOut () {
    this.cancelMonitoring()
    this._displays$.next([])
    this.localStorageService.removeItem('tv')
    this.localStorageService.removeItem('displays')
    this._currentTv$.next(undefined)
  }

  // Private ------------------------------------------------------------------------

  private ensureMonitoring () {
    if (!this._interval) {
      this._interval = setInterval(() => this.refreshTv(), 30 * 1000)
    }
  }

  private cancelMonitoring () {
    if (this._interval) {
      clearInterval(this._interval)
      this._interval = undefined
    }
  }

  private refreshTv () {
    return this.get().then((d) => {
      this._currentTv$.next(d)
    }, () => this.cancelMonitoring())
  }
}
