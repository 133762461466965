import { ApplicationRef, DoBootstrap, NgModule/*, isDevMode*/ } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from "@angular/common/http";
import { AuthInterceptor } from "./factories/auth_inteceptor";
// import { ServiceWorkerModule } from '@angular/service-worker';
import Bugsnag from "@bugsnag/js"
import { environment } from "../environments/environment"

Bugsnag.start({apiKey: '9ab1641ac0c59056a83ee0daae7c2162', appVersion: environment.version, releaseStage: environment.stage, enabledReleaseStages: [ 'production', 'staging' ] })

declare global {
  // tslint:disable-next-line
  interface Window {
    WebFont: { load: (params: any) => void}
  }
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    AppComponent,
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: !isDevMode(),
    //   // Register the ServiceWorker as soon as the application is stable
    //   // or after 30 seconds (whichever comes first).
    //   registrationStrategy: 'registerWhenStable:30000'
    // })
  ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    provideHttpClient(withInterceptorsFromDi())
  ] })
export class AppModule implements DoBootstrap {
  ngDoBootstrap (appRef: ApplicationRef): void {
    appRef.bootstrap(AppComponent)
  }
}
