import { Injectable } from '@angular/core'
import { environment } from 'environments/environment'

// @ts-expect-error 'Faye needs to go'
import * as Faye from 'faye'

@Injectable({providedIn:'root'})
export class $faye {
  private _client: Faye.client

  constructor () {
    this._client = new Faye.Client(environment.fayeEndpoint)
  }

  publish (channel: string, data: any) {
    return this._client.publish(channel, data)
  }

  subscribe (channel: string, callback: (...args: any[]) => void) {
    return this._client.subscribe(channel, callback)
  }

  get (channel: string) {
    return new Promise((resolve, _reject) => {
      const sub = this._client.subscribe(channel, (data: any) => {
        resolve(data);
        sub.cancel();
      });

      // If there's any error mechanism in `_client.subscribe`, you can add it here.
      // sub.onError((error) => {
      //   reject(error);
      // });
    });
  }

  bind = (bindevent: any, callback: () => void) => this._client.bind(bindevent, callback)

  state () { return (this._client.getState()) }
}
