import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'

@Injectable({providedIn:'root'})
export class KlassService {
  constructor (private $http: HttpClient) { }

  get (classId: number) {
    return this.$http.get(`/api/program_sessions/${classId}`).toPromise().then(r => r, r => { throw r.error })
  }
}
