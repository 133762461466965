import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpHeaders, HttpErrorResponse } from '@angular/common/http'
import { Observable, throwError } from 'rxjs';
import { AuthService, TvService } from 'app/services';
import { Injector, Inject, Injectable, inject } from '@angular/core'
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Injectable({providedIn:'root'})
export class AuthInterceptor implements HttpInterceptor {
  authService: AuthService
  tvService = inject(TvService)

  constructor (@Inject(Injector) injector: Injector, private router: Router) { //
    this.authService = injector.get<AuthService>(AuthService)
  }

  intercept (req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (req.url.startsWith('http')){ return next.handle(req) }
    const url = `${environment.apiEndpoint}${req.url}`
    const headers = { 'Content-Type': 'application/json', 'x-app-uid': 'influx.tv' } as Record<string, string>
    const display = this.tvService.currentTv
    if (display) {
      headers['Authorization'] = `Token token="${display.auth_token}"`
    }
    else if (this.authService.currenthAuth) {
      headers['Authorization'] = `Token token="${this.authService.currenthAuth.api_token}"`
    }

    // Requests are immutable so clone it
    const clonedRequest = req.clone({ url, headers: new HttpHeaders(headers) })
    return next.handle(clonedRequest)
      .pipe(catchError((err: any, _caught) => {
        if (err instanceof HttpErrorResponse && err.status === 401) {
          // eslint-disable-next-line no-console
          console.info(`HTTP 401 (${req.url}) =`, err.error, ';')
          this.authService.deauthorize()
          this.router.navigateByUrl('/')
        }
        return throwError(err)
      }))
  }
}

