import { Session } from "./session";

export class Display {
  // id:         number; // We don't need this and it probably shouldn't come through
  name: string;
  short_id: string;
  auth_token: string;
  facility: { name: string }
  org: { name: string }
  session?: Session;
  theme: Theme;
  channel: ChannelData;
  message?: IMessageData;

  constructor (json: Partial<Display>) {
    Object.assign(this, json)
  }
}

export type ChannelData =
  | {
    type: 'blank'
  }
  | {
    type: 'whiteboard'
    id: number,
    date: string
  }
  | {
    type: 'attendees'
    session_id: number
  }
  | {
    type: 'warmup' | 'warmdown'
    whiteboard_id: number
    session_id: number
    workout_id: number
  }
  | {
    type: 'workout'
    session_id: number
    workout_id: number
  }
  | {
    type: 'classResults'
    session_id: number
    workout_id: number
  }
  | {
    type: 'leaderboard'
    whiteboard_id: number
    date: string
  }

export interface IMessageData {
  text: string
  type?: string
  style?: string
}

export interface Theme {
  id: number;
  name: string;
  background_color: string;
  background_url: string;
  title: FontOption;
  header: FontOption;
  text: FontOption;
  notes: FontOption;
  updated: number;
}

export interface FontOption {
  font_type: string;
  font_color: string;
}
