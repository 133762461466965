import { HttpClient } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { Leaderboard, WorkoutResults } from 'app/models'
import { catchError } from 'rxjs'

@Injectable({providedIn:'root'})
export class WorkoutResultsService {
  constructor (private $http: HttpClient) { }

  listForDay (whiteboardId: number, date: string) {
    return this.$http.get<Leaderboard[]>('/api/workout_results', { params: { whiteboard_id: whiteboardId, date } }).toPromise()
    .then(r => r, r => { throw r.error })
  }

  listForEntry (sessionId: number, whiteboardEntryId: number) {
    return this.$http.get<WorkoutResults>('/api/workout_results', { params: { entry_id: whiteboardEntryId, session_id: sessionId } })
    .pipe(catchError( r => { throw r.error }))
  }
}
