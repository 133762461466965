export class WhiteboardItem {
  type: 'workout' | 'warmup' | 'warmdown'
  workout?: {
    name: any
    description: string
  }
  notes: any

  constructor (json: Partial<WhiteboardItem>) {
    Object.assign(this, json)
  }
}
