import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router } from '@angular/router';
import { Display, Facility } from 'app/models';
import { AuthService, TvService } from 'app/services';

@Component({
  selector: 'configure-box',
  templateUrl: './index.html',
  styleUrl: './index.scss',
  standalone: true,
  imports: [CommonModule, FormsModule]
})
export class ConfigureBoxComponent {
  auth: {email?: string, password?: string} = {}
  tv: { facility_id: number; facility_name: string; user_agent: string, name?: string }
  selected?: Facility
  viewstate: 'login' | 'tvs' | 'new_tv' = 'login'
  message: string
  facilities: Facility[]
  @Output()
  close = new EventEmitter<Display>()

  constructor (private authService: AuthService,
    private tvService: TvService,
    private router: Router) {}

    login (email: string, password: string) {
      this.message = null
      return this.authService.login(email, password).subscribe({
        next: result => {
          this.facilities = result.facilities.sort((a, b) => a.name.localeCompare(b.name));
          this.viewstate = 'tvs'
        },
        error: response => {
          this.message = response.statusText
        }
      })
    }

    create (tv: Partial<Display>) {
      this.message = null
      this.tvService.setFacility(this.selected)
      return this.tvService.create(tv).then(display => {
        this.tvService.set(display)
        this.tvService.list() // We don't use the result but it should update our list of tvs in the background
        this.router.navigateByUrl(`/${display.short_id}/welcome`)
        this.close.emit(display)
      }, response => { this.message = response.message })
    }

    showNew (facility: Facility) {
      this.tv = {
        user_agent: window.navigator.userAgent,
        facility_name: facility.name,
        facility_id: facility.id
      }
      this.viewstate = 'new_tv'
    }

    cancelNew () {
      this.tv = null
      this.viewstate = 'tvs'
    }

    selectDisplay (facility: Facility, display: Display) {
      this.tvService.setFacility(facility)
      this.tvService.set(display)
      this.router.navigateByUrl(`/${display.short_id}/welcome`)
      this.close.emit( display )
    }
}
