import { HttpClient, HttpErrorResponse } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { firstValueFrom } from "rxjs"

@Injectable({providedIn:'root'})
export class WhiteboardsService {
  constructor (private $http: HttpClient) { }

  get (whiteboardId: number, date: string): any {
    const params = {} as any
    if (date) { params['date'] = date}
    return firstValueFrom(this.$http.get<any>(`/api/whiteboards/${whiteboardId}`, {params})).then(r => r, (r: HttpErrorResponse) => { throw r.error })
  }
}
