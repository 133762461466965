import { NgModule } from '@angular/core'
import { RouterModule, Routes } from '@angular/router'
import { HomePage } from './pages/home'
// import { canActivate } from './factories/auth_guard'
import DisplayBasePage from './pages/tv'
import { tvActivate } from './factories/auth_guard'

export const routes: Routes = [
  { path: '', component: HomePage, pathMatch: 'full' },
  { path: ':tv_id',
    component: DisplayBasePage,
    canActivate: [tvActivate()],//[canActivate],
    data: { name: 'tvRoute' },
    children: [
      { path: '', loadComponent: () => import('./pages/tv/blank/index')},
      { path: 'welcome', loadComponent: () => import('./pages/tv/welcome/index')},
      { path: 'class/:id',
        // template: '<router-outlet></router-outlet>', // class',
        children:[
          { path: '',                         loadComponent: () => import('./pages/tv/class/attendees')}, // class.attendees',
          { path: 'entry/:entryId',          loadComponent: () => import('./pages/tv/class/whiteboard_entry')}, // class.entry',
          { path: 'results/:entryId',        loadComponent: () => import('./pages/tv/class/results')}, // class.results',
          // { path: '/timer',                   loadComponent: () => import('./pages/class/timer')} // class.timer',
        ]
      },
      { path: 'leaderboard/:id/:date',   loadComponent: () => import('./pages/tv/leaderboard/index')},
      { path: 'whiteboard/:id/:date',    loadComponent: () => import('./pages/tv/whiteboard/index')},
      { path: 'debug',                    loadComponent: () => import('./pages/tv/debug/index')}
    ]
  },
  { path: 'error/unknown',           loadComponent: () => import('./pages/error/unknown')}
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes,{
      enableTracing: false,
      useHash: false,
      bindToComponentInputs: true
    }),
  ],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {}
