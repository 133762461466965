import { BehaviorSubject } from 'rxjs'
import { Injectable } from '@angular/core'
import { IMessageData } from 'app/models'

@Injectable({providedIn:'root'})
export class MessageService {
  private _currentMessage = new BehaviorSubject<IMessageData>(undefined)


  show (data: IMessageData) {
    if (data.text && data.text.length > 0) {
      this._currentMessage.next({ style: data.type, text: data.text })
    } else {
      this._currentMessage.next(undefined)
    }
  }

  get message$ () {
    return this._currentMessage.asObservable()
  }
}
