import { NgIf } from '@angular/common'
import { Component, OnDestroy, OnInit } from '@angular/core'
import { IMessageData } from 'app/models'
import { MessageService } from 'app/services'
import { Subscription } from 'rxjs'

@Component({
  selector: 'display-message',
  template: `<div *ngIf="!!message" class="display-message {{ message.style }}" [innerText]="message.text"></div>`,
  imports: [NgIf],
  standalone: true
})
export class DisplayMessageComponent implements OnInit, OnDestroy {
  private sub: Subscription
  message: IMessageData

  constructor (private messageService: MessageService) { }

  ngOnInit () {
    this.sub = this.messageService.message$.subscribe((message: IMessageData) => {
      this.message = message
    })
  }

  ngOnDestroy () {
    this.sub?.unsubscribe()
  }
}
